import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import profile from '../images/profile.jpg';


const About = ({ intl }) => (
  <Layout>
    <SEO lang={intl.locale} title={intl.formatMessage({ id: "about.title" })} />
    <h1>
      <FormattedMessage id="about.title" />
    </h1>
    <p><b>
      <FormattedMessage id="about.name" />
      <br />
      <FormattedMessage id="about.name_title" />
    </b>
    </p>

    <p>
      <img src={profile} alt="Dilia Fuentes Avila de van Essen" width="300" />
    </p>

    <p>
      <FormattedMessage id="about.intro" />
    </p>
    <p>
      <FormattedMessage id="about.text" />
    </p>
    <h3>
      <FormattedMessage id="about.accreditation" />
    </h3>
    <ul>
      <li>
        <FormattedMessage id="about.cert_icf" />
      </li>

      <li>
        <FormattedMessage id="about.cert_mba" />
      </li>

      <li>
        <FormattedMessage id="about.cert_ba" />
      </li>

      <li>
        <FormattedMessage id="about.cert_imagery" />
      </li>

      <li>
        <FormattedMessage id="about.cert_HR" />
      </li>

      <li>
        <FormattedMessage id="about.cert_theo" />
      </li>
    </ul>

  </Layout>
)

export default injectIntl(About)
